import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Drawer from '../../components/Drawer'
import { Outlet } from 'react-router-dom'

const CollectPage = ({ locale, setLocale }) => {
  const [pong, setPong] = useState()

  useEffect(() => {
    axios.get('/api/csrf/').then(
      (res) => {
        setPong(res.data.csrfToken)
        console.log({ pong })
      },
      (err) => {
        console.log('err is : ', err)
      }
    )
  }, [])

  return (
    <Drawer locale={locale} setLocale={setLocale}>
      <Outlet />
    </Drawer>
  )
}

export default CollectPage
